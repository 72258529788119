<template>
  <div class="classFication">
    <Heador />
    <classBody />
    <NavBar />
  </div>
</template>

<script>
import Heador from "./cheader";
import classBody from "./classbody";
export default {
  data() {
    return {};
  },
  components: {
    Heador,
    classBody,
  },
};
</script>

<style lang="less" scoped>
.classFication {
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;
  padding: 0 0 1.28rem /* 64/50 */;
}
</style>
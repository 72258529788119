<template>
  <div class="classbody dark_bg">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <div class="menuwrapper dark_bg" ref="menuWrapper">
      <van-sidebar :active-key="activeKey" @change="onChange" class="content">
        <van-sidebar-item
          v-for="(item, index) in slidebarItem"
          :key="index"
          :title="item.title"
          @click="tabtap(item)"
        />
      </van-sidebar>
    </div>
    <van-loading type="spinner" v-if="showLoading" />
    <div class="goodswrapper dark_bg" ref="goodsWrapper" v-else>
      <!-- <van-row gutter="4" type="flex">
        <van-col
          span="8"
          v-for="item in slideContent"
          :key="item.id"
          @click.native="clickActive(item.id)"
        >
          <div>
            <p class="class-img">
              <img :src="item.img" />
            </p>

            <p class="class-txt">{{ item.title }}</p>
            <p class="class-txt">￥{{ item.price / 100 }}</p>
          </div>
        </van-col> 
        </van-row> -->
      <van-card
        style="background-color: #fff; border-radius: 0.1rem; margin: 0.1rem"
        v-for="item in slideContent"
        :key="item.id"
        :price="(item.price / 100).toFixed(2)"
        :desc="item.description"
        :title="item.title"
        :thumb="item.img"
        @click.native="clickActive(item)"
      />

      <!-- <van-card
        num="2"
        price="2.00"
        desc=""
        title="商品标题"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      /> -->
    </div>
  </div>
</template>

<script>
import { Sidebar, SidebarItem, Row, Col, Collapse, Loading } from "vant";
import BScroll from "@better-scroll/core";
export default {
  data() {
    return {
      activeKey: 0,
      showLoading: false,
      slidebarItem: [],
      slideContent: [],
      isLoading: true,
    };
  },
  components: {
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Loading.name]: Loading,
  },
  created() {
    this.getClassList();
  },
  computed: {
    current: function () {
      return this.slidebarItem[0].categoryName;
    },
  },
  methods: {
    onChange(key) {
      this.activeKey = key;
    },
    // 初始化滚动值
    initFirstScroll() {
      if (!this.scroll) {
        this.scroll = new BScroll(this.$refs.menuWrapper, {
          probeType: 3,
          click: true,
          // scrollY: true,
          // bounce: {
          //   left: false,
          //   right: false,
          //   top: true,
          //   bottom: true
          // },
          // scrollX: false,
          // mouseWheel: {
          //   // pc端同样能滑动
          //   speed: 20,
          //   invert: false
          // },
          // useTransition: true // 防止iphone微信滑动卡顿
        });
      } else {
        this.scroll.refresh();
      }
    },
    initSecondScroll() {
      // if (!this.goodscroll) {
      this.goodscroll = new BScroll(this.$refs.goodsWrapper, {
        probeType: 3,
        click: true,
        // bounce: {
        //   left: false,
        //   right: false,
        //   top: true,
        //   bottom: true
        // },
        // scrollY: true,
        // scrollX: false,
        // mouseWheel: {
        //   // pc端同样能滑动
        //   speed: 20,
        //   invert: false
        // },
        // useTransition: true // 防止iphone微信滑动卡顿
      });
    },
    // 一级分类数据
    getClassList() {
      this.$api.classfication.getFoodsSelected({ type: "5" }).then((res) => {
        if (res.errno === 200) {
          this.slidebarItem = res.data;
          this.slideContent = res.data[0].spuDTOList;
          this.currentId = res.data[0].id;
          this.$nextTick(() => {
            this.initFirstScroll();
          });
          // this.getSecondClassTab(this.slidebarItem[0].id);
          // 默认显示第一个父分类的子级目录
          this.isLoading = false;
        }
      });
    },
    // 二级分类数据
    // getSecondClassTab(id) {
    //   this.showLoading = true;
    //   this.$api.classfication
    //     .secondClassListAddress({
    //       categoryId: id
    //     })
    //     .then(res => {
    //       if (res.code == 100) {
    //         this.showLoading = false;
    //         this.slideContent = res.returnValue;
    //         this.isLoading = false;
    //         // 初始化better-scroll  //该方式没有行通
    //         this.$nextTick(() => {
    //           this.initSecondScroll();
    //         });
    //         // 初始化better-scroll 转用定时器
    //         // setTimeout(this.initSecondScroll, 200);
    //       }
    //     });
    // },
    //一级分类点击
    tabtap(item) {
      //console.log(item);
      this.currentId = item.id;
      this.slideContent = item.spuDTOList;
      this.$nextTick(() => {
        this.initSecondScroll();
      });
    },
    clickActive(item) {
      // console.log(item);

      // let skuItem = {
      //   skuId: this.checkedId,
      //   spuId: this.goods.id,
      //   title: this.goods.title,
      //   price: this.goods.skuList[this.checkedIndex].price,
      //   img: this.goods.img,
      //   num: this.count,
      //   sales: this.sales,
      //   takeWay: "buy",
      // };
      // let skuGoodsList = [];
      // skuGoodsList[0] = skuItem;
      // localStorage.setItem("skuGoodsList", JSON.stringify(skuGoodsList));
      this.$router.push({
        path: `/goodsdetail/${item.id}`,
        query: {
          isFood: true,
        },
      });
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";
.classbody {
  width: 100%;
  position: absolute;
  top: 1rem /* 50/50 */;
  bottom: 1.2rem /* 60/50 */;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  .list(row);
  .menuwrapper {
    background-color: #fff;
    .van-sidebar {
      background-color: #fff;
      padding: 0.32rem /* 16/50 */ 0.12rem /* 6/50 */;
    }
    .van-sidebar-item {
      background-color: #fff;
      border: none;
      text-align: center;
      padding: 0.32rem /* 16/50 */ 0rem;
      &.van-hairline::after {
        border: none;
      }
    }
    .van-sidebar-item--select {
      color: #fff;
      border: none;
      border-radius: 0.64rem /* 32/50 */;
      padding: 0.1rem /* 5/50 */ 0.1rem /* 5/50 */;
      // background-color: @van_sidebar_selected_border_color;
    }
  }
  .van-loading {
    margin: 0.32rem /* 16/50 */ auto;
  }
  .goodswrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.06rem /* 3/50 */ 0;
    background: #f0f0f0;
    .van-row {
      flex-wrap: wrap;
      .van-col {
        flex-grow: auto;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 3rem;
          background-color: #fff;
          text-align: center;
          border-radius: 0.06rem /* 3/50 */;
          margin-bottom: 4px;
          padding: 2px;
        }
        .class-img {
          height: 1.76rem /* 88/50 */;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 0.06rem /* 3/50 */;
            border-top-right-radius: 0.06rem /* 3/50 */;
          }
        }
        p.class-txt {
          font-size: 0.24rem /* 12/50 */;
          box-sizing: border-box;
          // padding: 0.12rem /* 6/50 */ 0;
        }
      }
    }
  }
}
</style>
